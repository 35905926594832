import { useEffect, useState } from "react";
import { Base64 } from "js-base64";
import { Button } from "antd";
import AELOGO from "../../../assets/imgs/AE_Qube_color_rgb.png";
import "./ProductsList.scss";
import { getProductsList } from "../../../constants";
import { getRealmFromUrl } from "../../../helpers";
import { ProjectCard } from "../../../components/ProjectCard";
import { fetchUser } from "../../../components/LandingPage/functions/fetchUser";
import {
  getUserTerms,
  productListUrl,
  tenantListUrl,
} from "../../../helpers/RestApisHelper/RestApis";
import { useAuth } from "../../../hooks";
import _ from "lodash";
import { LoadingSpinner } from "../../../components/common";
import getRedirectUri from "../../../helpers/getRedirectUrl";
import { useHistory } from "react-router-dom";
import ProfileMenu from "../../../components/ProfileMenu/UI/ProfileMenu.component";
import { useUserAvatar } from "../../../hooks/Profile/useUserAvatar";
import { MenuItem } from "../../../types";
import axios from "axios";
import { toast } from "react-toastify";
import { Message } from "ae-ui-components";

export interface ProductType {
  name: string;
  desc: string;
  baseUrl: string;
  learnMore: string;
  id: string;
  productId: string;
}

const ProductsList = () => {
  const [realmsFromFetch, setRealmFromFetch] = useState<
    { id: number; name: string }[]
  >([]);
  const history = useHistory();
  const [loadingButton, setLoadingButton] = useState<boolean>();
  const [menuOptions, setMenuOptions] = useState<MenuItem[]>([
    {
      label: "About",
      key: "about",
      onClick: () => {
        // eslint-disable-next-line no-restricted-globals
        location.href = "https://analytic-edge.com/";
      },
    },
  ]);

  const handleGetUserTerms = async () => {
    try {
      setLoading(true);
      const email = getEmail();
      const response = await axios.post(getUserTerms, {
        email: email?.trim(),
      });
      if (!response.data?.termsCheck) {
        history.push("/termsandcondition");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast(<Message type="error" message="Error in fetching user details" />, {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    handleGetUserTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getEmailFromToken, logout, getName, getEmail, idTokenParsed } =
    useAuth();
  const { avatar } = useUserAvatar();

  useEffect(() => {
    setLoadingButton(true);
    const email = getEmail();
    const data = JSON.stringify({
      email: email?.trim(),
    });
    const url = tenantListUrl;
    if (email) {
      fetchUser(url, data)
        .then((realms) => {
          const tenantOption = {
            label: "Change Tenant",
            key: "change-Tenant",
            onClick: () => {
              history.push("/tenants");
            },
          };
          console.log({ realms });
          setMenuOptions((prev) =>
            realms.data.length > 1 ? [tenantOption, ...prev] : prev
          );
          setRealmFromFetch(realms.data);
          setLoadingButton(false);
        })
        .catch((error) => {
          if (error) {
            console.log({ error });
          }
          setLoadingButton(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [allproducts] = useState<ProductType[]>(
    JSON.parse(
      Base64.decode(getProductsList())
        .toString()
        .trim()
        .replace(/&quot;/gi, '"')
    ).map((each: any) => {
      return {
        name: each.name,
        desc: each.desc,
        baseUrl: each.baseUrl,
        learnMore: each.learnMore,
        id: each.id,
        productId: each.productId,
      };
    })
  );
  console.log({ JSON: allproducts });
  const [currentTenant] = useState<string | null>(getRealmFromUrl());
  const [currentProducts, setCurrProducts] = useState<{ name: string }[]>([]);
  const [loading, setLoading] = useState(true);
  console.log({ currentProducts });
  useEffect(() => {
    if (currentTenant && _.isEmpty(currentProducts)) {
      const url = productListUrl;
      const email = getEmailFromToken();
      const data = JSON.stringify({ email });
      fetchUser(url, data)
        .then((val) => {
          if (val.data.length) {
            setCurrProducts(val.data.map((each: any) => ({ name: each.name })));
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log({ err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant]);
  console.log({
    allproducts,
    currentTenant,
    origin: window.origin,
    name: getName(),
    idTokenParsed,
  });
  return (
    <div className="products-list-container">
      <div className="products-list-inner-container">
        <div className="products-list-container-header">
          <div className="products-list-container-header-image">
            <img src={AELOGO} alt="ae-qube-logo" />
            <div>
              <p>
                Your all-in-one toolkit for marketing analytics and data-driven
                decision-making
              </p>
            </div>
          </div>

          <div className="product-list-profile-options flex items-center">
            {realmsFromFetch.length > 1 ? (
              <div style={{ marginRight: "10px" }}>
                <Button
                  className="default-button-class"
                  style={{ width: "90px", height: "36px", display: "none" }}
                  onClick={() => history.push("/tenants")}
                  loading={loadingButton}
                >
                  Back
                </Button>
              </div>
            ) : null}
            <Button
              className="default-button-class mr-2"
              style={{ width: "90px", height: "36px" }}
              onClick={() =>
                logout({
                  redirectUri: getRedirectUri(),
                })
              }
            >
              Logout
            </Button>
            <ProfileMenu avatar={avatar} items={menuOptions} />
          </div>
        </div>
        <div className="products-list-container-text">
          <div>
            <p>Welcome{!!getName ? `, ${_.startCase(getName())}` : ""}</p>
          </div>
          <div>
            <p>Select a product to get started.</p>
          </div>
        </div>

        <div className="products-list-container-tenant-list-container">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingSpinner spinning />
            </div>
          ) : (
            allproducts?.map((eachProduct) => {
              return (
                <ProjectCard
                  eachProduct={eachProduct}
                  isUnAvailable={
                    !currentProducts.some((each) =>
                      each.name
                        .toLowerCase()
                        .includes(eachProduct.productId.toLowerCase())
                    )
                  }
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
