import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProductsPage, ReportsPage } from "../../../../pages";
import { RealmsPage } from "../../../../pages/RealmsPage";
import { CustomReportsPage } from "../../../../pages/customReportsPage";
import { TermsPage } from "../../../../pages/termsAndConditionPage/TermsPage";

const ProtectedRoutes = () => {
  return (
    <Switch>
      <Redirect exact from={"/"} to={"/products"} />
      <Route exact path={["/products"]} component={ProductsPage} />
      <Route exact path={["/tenants"]} component={RealmsPage} />

      <Route exact path={["/report/:reportId"]} component={CustomReportsPage} />

      <Route exact path={["/reports"]} component={ReportsPage} />
      <Route exact path={["/termsandcondition"]} component={TermsPage} />
    </Switch>
  );
};

export default ProtectedRoutes;
