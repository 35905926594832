import { getTenantOrgDetailsEndpoint } from "../../constants";

const baseUrl = getTenantOrgDetailsEndpoint();

const tenantListUrl = `${baseUrl}/userTenants`;
const productListUrl = `${baseUrl}/userProducts`;
const sendEmailUrl = `${baseUrl}/sendPasswordResetEmail`;
const resetPassword = `${baseUrl}/resetPassword`;
const signUpUrl = `${baseUrl}/userSignup`;
const getAllReportsUrl = `${baseUrl}/getReports`;
const getReportsByIdUrl = `${baseUrl}/getReportById`;
const getPowerBiTokenByUrl = `${baseUrl}/generatePowerbiToken`;
const validateOTP = `${baseUrl}/otp/validateOtp`;
const resendOTP = `${baseUrl}/otp/resendOtp`;
const additionalDetails = `${baseUrl}/leads/update`;
const getTempUser = `${baseUrl}/userSignup/getUser/`;
const updateTempUser = `${baseUrl}//userSignup/update`;
const getUserTerms = `${baseUrl}/getuserDetails`;
const updateTerms = `${baseUrl}/updateUserTerms`;

export {
  tenantListUrl,
  productListUrl,
  sendEmailUrl,
  resetPassword,
  signUpUrl,
  getAllReportsUrl,
  getReportsByIdUrl,
  getPowerBiTokenByUrl,
  validateOTP,
  resendOTP,
  additionalDetails,
  getTempUser,
  updateTempUser,
  getUserTerms,
  updateTerms,
};
