import { Button } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { updateTerms } from "../../helpers/RestApisHelper/RestApis";
import { useAuth } from "../../hooks";
import { toast } from "react-toastify";
import { Message } from "ae-ui-components";
import "./TermsPage.scss";
import getRedirectUri from "../../helpers/getRedirectUrl";

export const TermsPage = () => {
  const history = useHistory();
  const { getEmail, logout } = useAuth();

  const handleUpdateTerms = async () => {
    try {
      const email = getEmail();
      const response = await axios.post(updateTerms, {
        email: email?.trim(),
      });
      if (response) {
        history.push("/products");
      }
    } catch (err) {
      toast(<Message type="error" message="Something went wrong!" />, {
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="terms-condition-container">
      <div className="terms-content">
        <h1 className="terms-header">Terms and Conditions</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
          consequat massa quis enim. Donec pede justo, fringilla vel, aliquet
          nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
          venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium.
          Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean
          vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat
          vitae, eleifend ac, enim.
        </p>
        <p>
          Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
          Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum.
          Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur
          ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas
          tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit
          amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel,
          luctus pulvinar, hendrerit id, lorem.
        </p>
        <p>
          Sed vestibulum eleifend ligula eget porttitor. Nulla facilisi.
          Pellentesque viverra sapien ultricies venenatis sodales. Donec vitae
          venenatis leo. Nullam elit erat, consequat ut felis et, vestibulum
          luctus ligula. Vestibulum est lorem, commodo at dictum vitae, accumsan
          vel ipsum. Quisque facilisis semper urna id vehicula. Nulla tristique
          ullamcorper nisi, sed consectetur tortor eleifend sit amet. Nullam at
          sodales orci. Nam id pharetra nisi, sed venenatis est. Duis vulputate
          lectus eu lectus pellentesque, non rutrum nulla aliquam. Aenean
          malesuada est at nisi ullamcorper, id rutrum turpis mattis.
        </p>
        <p>
          Nunc in justo in dolor accumsan tempus ac sed risus. Class aptent
          taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Nunc feugiat nisl a ligula consectetur aliquet. Sed in
          tincidunt ante. Vivamus ante nisl, ultrices a rhoncus vel, ultricies
          et felis. Donec auctor facilisis quam consequat ultricies. Sed felis
          diam, porttitor et eleifend quis, ultrices et ante.
        </p>
        <p>
          Etiam bibendum est a tortor mollis, rhoncus lobortis mi aliquet. In
          hac habitasse platea dictumst. Aliquam lobortis ultricies faucibus.
          Aliquam enim odio, tempor vitae ante a, auctor aliquam massa.
          Vestibulum aliquet finibus dictum. Sed lorem ante, euismod at
          ullamcorper sit amet, fringilla a felis. Praesent commodo, mi sed
          aliquam commodo, arcu dolor sollicitudin diam, sed lacinia arcu urna
          sed arcu. Phasellus vitae lacus non dui pellentesque molestie id
          maximus odio. Sed tempus leo est. Nullam ultricies tortor nec enim
          convallis elementum.
        </p>
        <p>
          Etiam euismod scelerisque elit, non dictum nunc imperdiet ut.
          Suspendisse dapibus ipsum lacus, eu accumsan diam vehicula sed. Mauris
          a nulla condimentum, rutrum lacus ac, auctor nisi. Sed sodales
          consectetur commodo. Nunc vel justo sodales, ornare urna a,
          ullamcorper massa. Nulla ut maximus felis. Nullam at ante nulla. Nunc
          tempus orci in metus lacinia, eget posuere erat porta. Aenean in
          turpis feugiat nunc molestie fringilla ut rhoncus nunc. Nam aliquet
          eros id lorem rutrum auctor. Pellentesque faucibus libero id metus
          facilisis tristique. Sed finibus blandit luctus. Nullam laoreet
          sollicitudin leo id egestas. Donec ullamcorper ut leo quis molestie.
          Sed faucibus augue est, in placerat ex efficitur eget.
        </p>
        <p>
          Sed sit amet condimentum tortor. Suspendisse non scelerisque velit.
          Nulla in lectus nec erat fringilla facilisis in at lorem. Duis
          dapibus, nunc sit amet ultricies venenatis, leo est semper magna, non
          sollicitudin ex magna ut enim. Etiam ut lorem scelerisque, malesuada
          est vitae, cursus mauris. Praesent accumsan ligula sed nulla tempus
          aliquet. Nullam auctor in arcu id iaculis. Donec porttitor felis in
          massa bibendum pharetra et sit amet mi. Donec et mattis lectus.
          Curabitur tempus orci tellus, vitae ornare nulla blandit eu. Morbi
          ligula erat, porttitor quis quam non, viverra malesuada leo. Donec
          consectetur fringilla pellentesque. Aenean dictum est nec urna tempus
          tincidunt. Praesent massa orci, vestibulum non tempus sed, hendrerit
          ut eros. Vestibulum in sagittis metus. In hac habitasse platea
          dictumst.
        </p>
        <p>
          Donec maximus mauris in urna congue, sit amet rhoncus purus tristique.
          Nam iaculis maximus eros sed dictum. Donec ac fermentum orci. Cras
          consectetur turpis vitae quam iaculis pretium. Sed fermentum
          pellentesque ligula nec ornare. Donec fringilla dictum odio, dictum
          lobortis arcu venenatis eu. In pretium nisl velit, et consequat lectus
          auctor sit amet. Aliquam erat volutpat. Aenean ac iaculis turpis, vel
          molestie mauris.
        </p>
        <p>
          Sed non sem felis. Nullam tempor erat nec orci mollis, nec aliquam
          libero fringilla. Cras ante erat, viverra eu egestas id, dignissim sit
          amet lorem. Praesent vel vestibulum risus. Donec placerat nisi velit,
          maximus consequat magna rutrum et. Duis eget ultrices felis. Praesent
          a nisi euismod, sagittis tellus eget, fermentum ex. Morbi pulvinar
          elementum aliquet.
        </p>
        <p>
          Ut ut semper massa. Duis eu tortor vel diam blandit interdum vel ut
          purus. Suspendisse tincidunt tristique lacus tincidunt fringilla.
          Etiam rhoncus ipsum nec lectus maximus, vel vehicula turpis pretium.
          Nullam luctus, sapien viverra ultrices pulvinar, mauris mi convallis
          tortor, vitae porta ante enim sit amet lorem. Integer feugiat dapibus
          massa id suscipit. Phasellus iaculis lectus mi. Sed sed tellus sed
          lacus faucibus consectetur. Etiam gravida, diam eget commodo
          elementum, velit velit euismod neque, id faucibus dui diam nec augue.
          Pellentesque iaculis ultrices odio, id sagittis erat. Nullam vitae
          elementum nibh, eget rhoncus est. Duis ac sapien nec orci aliquet
          dictum. Cras tempor porttitor aliquet. Pellentesque ac arcu at sapien
          semper gravida et ut risus.
        </p>
        <p>
          In suscipit, lectus at commodo vehicula, nunc magna iaculis lectus,
          vitae aliquam tortor nibh ullamcorper leo. Sed pulvinar nunc eget arcu
          lacinia, vel ornare lectus eleifend. Morbi malesuada in orci vel
          lacinia. Nunc id suscipit leo. Maecenas nec mollis augue. Ut porta
          sapien lacus, eget elementum leo sollicitudin vel. Curabitur consequat
          mollis arcu, vitae egestas quam vehicula vestibulum. Pellentesque ut
          purus augue. Phasellus in enim mi. Sed ut magna blandit, tempor magna
          non, aliquet felis. Duis nunc ipsum, lobortis sed est sit amet,
          suscipit volutpat mi. In felis dui, lacinia sed velit sed, tristique
          luctus ipsum. Mauris lobortis erat ac mauris sagittis, et faucibus
          purus tincidunt. Proin ut ex porta, tristique dolor pulvinar, interdum
          nisi. Suspendisse rutrum, eros sed tempor hendrerit, nunc leo maximus
          ipsum, in maximus nisi nisi id eros. Vivamus et tempus sem. Aenean ut
          semper ligula. Nulla facilisi. Curabitur augue sem, rutrum a mauris
          non, suscipit luctus ligula. Orci varius natoque penatibus et magnis
          dis parturient montes, nascetur ridiculus mus. Sed feugiat feugiat
          lorem eget vehicula. Aenean luctus nisi eget vestibulum dignissim.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Suspendisse odio tortor, cursus eu eros eu,
          rutrum imperdiet enim. In ultricies, tellus nec interdum hendrerit,
          dolor odio aliquet erat, at sollicitudin enim lorem sit amet mi. Cras
          a sapien magna. Curabitur elementum ac diam ut sollicitudin. Ut dui
          tellus, laoreet quis tristique id, aliquet quis sapien. Morbi commodo
          hendrerit lacus vitae hendrerit. Aliquam erat volutpat. Proin at
          posuere sapien, at pharetra ante. Vivamus molestie libero mauris, vel
          accumsan quam ultricies ac. Vestibulum suscipit, ligula vel mattis
          condimentum, velit elit scelerisque libero, sed posuere nunc elit ac
          magna. In bibendum aliquet sem ac pharetra. Aliquam tempor, lacus vel
          viverra hendrerit, sem orci maximus arcu, eu tincidunt urna dui
          faucibus nunc. Ut sed mauris nec nisl mollis mollis et a quam. Nam
          ante lacus, varius sed lacus et, rhoncus ornare turpis. In ex eros,
          volutpat sed posuere eget, convallis quis mi. Fusce sit amet vehicula
          turpis, a vehicula magna. Donec pellentesque dapibus odio, ac varius
          tellus. Etiam vitae sapien lorem. Morbi auctor sagittis mollis.
          Maecenas mi eros, sagittis eu dui sit amet, pulvinar fringilla erat.
          Sed fermentum turpis et tempus suscipit. Maecenas dictum est at
          dapibus sagittis.
        </p>
        <p>
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
          posuere cubilia curae; Donec nec rhoncus enim, sed consectetur risus.
          Praesent at ullamcorper neque, nec congue ex. Curabitur est ligula,
          aliquam sit amet arcu nec, imperdiet varius tortor. Donec eleifend
          nibh a dictum gravida. Cras non mi id elit luctus ultrices id vitae
          velit. Suspendisse vehicula, urna eu accumsan ultrices, purus ante
          euismod erat, eget dictum nisi sem et nisl. Donec nec nisl auctor dui
          dignissim varius lacinia tristique elit. In ultricies suscipit mattis.
          Curabitur rhoncus semper dui, id lobortis nunc maximus vel. Sed non
          velit quis mauris mattis ullamcorper. Nulla nisi leo, viverra non
          libero id, ullamcorper rutrum lacus.
        </p>
        <p>
          Aenean aliquam, lorem quis semper suscipit, felis nibh malesuada
          lacus, et semper dolor lacus sed nulla. Mauris et ipsum et urna dictum
          volutpat. Pellentesque condimentum, elit quis auctor pharetra, ante
          sem dignissim orci, nec finibus massa nisl tristique orci. Phasellus
          vel magna sit amet magna sollicitudin scelerisque non quis dui.
          Suspendisse potenti. Nulla et maximus diam, ut mattis lacus.
          Pellentesque eu ligula sed ligula consequat laoreet non non felis.
          Donec eget auctor tellus. Aenean nec nunc quis magna sodales convallis
          eu nec est. Quisque porta sagittis ligula, vel faucibus nulla vehicula
          id. Sed tellus nisl, fringilla id porta non, tincidunt quis sapien.
        </p>
        <p>
          Phasellus vitae arcu elit. Maecenas eu pulvinar eros. Sed sodales arcu
          elit, nec elementum arcu dictum quis. Etiam lobortis consectetur
          posuere. Orci varius natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Donec quis lectus turpis. Donec elit
          velit, euismod eu lobortis ac, egestas sed elit. In ac bibendum arcu.
          Aliquam convallis tellus sit amet purus tincidunt porttitor vel et
          lacus. Ut gravida tortor id nisl interdum, vel cursus leo pretium.
          Praesent malesuada magna lacus, eget auctor dui efficitur non. Etiam
          nec nunc quam. Aenean luctus dui et nibh auctor hendrerit. Maecenas ac
          tempor dolor, eget laoreet elit. Fusce a sem vitae lorem hendrerit
          laoreet sed ut leo. Duis tempor lectus mauris, lobortis pharetra dui
          varius in.
        </p>
        <p>
          Sed eget luctus nulla, a facilisis urna. Ut aliquet ligula enim, ut
          laoreet magna luctus a. Aenean eget odio condimentum, condimentum ante
          in, pellentesque nulla. Donec sit amet felis felis. Fusce aliquam diam
          nec tortor vestibulum rhoncus. Nullam id magna lacus. In a suscipit
          quam. Proin pretium at dolor a accumsan. Nulla vel pharetra nisl.
          Suspendisse odio neque, tristique sit amet velit at, cursus eleifend
          erat. Orci varius natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus.
        </p>
        <p>
          In eget nisl eget nunc condimentum interdum sit amet eu urna. Aenean
          quis vestibulum mi, scelerisque vulputate mauris. Aenean sed vehicula
          nisi, eget faucibus lectus. Quisque faucibus mauris nisl, in tincidunt
          urna imperdiet tincidunt. Integer id augue dui. Vivamus lacus ante,
          pellentesque ac consectetur malesuada, sollicitudin nec arcu. Sed
          ullamcorper mattis mi et sollicitudin.
        </p>
        <p>
          Pellentesque eget arcu sem. Quisque in porttitor nunc, at pellentesque
          quam. Etiam lacinia massa ac odio porta, et commodo ante fermentum.
          Quisque pellentesque laoreet justo vel posuere. Mauris efficitur nibh
          ante, eu mollis nulla dapibus eu. Maecenas et odio volutpat, fermentum
          tellus et, varius erat. Duis non hendrerit lectus. Nulla eleifend
          consectetur iaculis. Suspendisse consectetur leo lectus, et molestie
          augue faucibus in.
        </p>
        <p>
          Sed ac suscipit nibh. Suspendisse ipsum nunc, convallis eu nisl sed,
          condimentum gravida magna. Fusce in sem tincidunt, pretium turpis sed,
          porta dui. Phasellus interdum lacinia porta. In varius accumsan
          pellentesque. Nulla facilisi. Fusce felis libero, volutpat et
          consectetur ultricies, pharetra id justo.
        </p>
        <p>
          Praesent in tempor ligula. Donec ornare mauris nec orci feugiat
          lobortis. Nam tincidunt consequat ligula sit amet volutpat.
          Pellentesque at egestas justo. Aliquam id maximus enim, eget fringilla
          nibh. In hac habitasse platea dictumst. Ut sed nisl in mi molestie
          luctus. Nulla purus lorem, semper vitae libero sit amet, malesuada
          elementum augue. Sed commodo feugiat nisl sit amet condimentum.
          Integer congue lacus tellus, sit amet vehicula purus fermentum nec.
          Suspendisse ac ultricies tortor. Mauris sodales feugiat ex, sit amet
          posuere magna auctor id. Mauris id nunc enim. Cras sed lectus urna.
        </p>
        <p>
          Maecenas leo quam, dignissim ac fringilla a, accumsan non neque.
          Aliquam aliquam aliquet orci ac posuere. Ut eu tortor commodo,
          venenatis lacus eu, sodales mauris. Etiam convallis ipsum et libero
          feugiat, eget aliquet mi porta. Phasellus et velit eros. Nam justo
          erat, vulputate sit amet dolor eget, lacinia efficitur nibh. Fusce
          tempor faucibus elit, quis aliquam ipsum eleifend et. Mauris cursus
          leo ac lorem hendrerit, ut scelerisque magna varius. Donec at tellus
          nec leo sollicitudin ornare non et odio. Ut sed porttitor sapien, sit
          amet accumsan tortor. Quisque tristique et lacus quis rutrum
        </p>
        <p>
          Mauris non congue orci, ac suscipit mi. Curabitur eget dictum lorem.
          Duis eget est et dui pellentesque posuere. Phasellus facilisis magna
          mattis, luctus nunc eu, lacinia ante. Phasellus sit amet nulla
          lacinia, semper tortor non, rhoncus quam. Aliquam eu sem in urna
          dapibus egestas. Phasellus semper luctus dolor vitae finibus. Donec
          auctor eros eu massa volutpat, sed vulputate nisl efficitur. Duis
          purus est, congue et pulvinar a, sodales sit amet nibh. Vestibulum
          sodales lacinia eros sit amet vulputate.
        </p>
        <p>
          Maecenas tortor massa, viverra non ex eget, lacinia imperdiet libero.
          Suspendisse arcu risus, tempus in dolor eget, blandit rutrum urna.
          Donec id gravida est. Mauris tortor quam, mattis a diam vel, iaculis
          pharetra nibh. Quisque luctus vel dui in posuere. Sed velit nunc,
          rutrum id lorem et, euismod imperdiet neque. Pellentesque lorem ipsum,
          lacinia vitae est quis, semper efficitur ipsum. Sed efficitur est
          posuere facilisis ultricies. Quisque et pellentesque risus. Aenean
          fermentum velit ex, ac condimentum sapien feugiat sed. Cras imperdiet
          condimentum mi non ultricies. Cras cursus, tortor a semper tempor,
          diam quam iaculis lacus, vel luctus odio nulla non nibh.
        </p>
        <p>
          Suspendisse urna mi, consectetur id venenatis a, fringilla mattis est.
          Sed turpis justo, placerat quis porta id, euismod at purus. Nam vitae
          ante eget tortor fringilla bibendum. Duis placerat ullamcorper dolor
          vitae tincidunt. Suspendisse vehicula sodales elit, ac scelerisque
          ante tincidunt ut. Praesent at sagittis lectus, vitae iaculis nulla.
          In egestas dui lacus, non imperdiet sem pulvinar vitae. Mauris ac mi
          sem. Quisque quis elit varius sapien egestas ultricies ac vel ipsum.
        </p>
        <p>
          Vivamus interdum porttitor pellentesque. Vivamus volutpat quam a felis
          vulputate luctus. Pellentesque nunc enim, dapibus eget hendrerit eget,
          blandit id sapien. Maecenas et porta ante. Curabitur ut pellentesque
          augue. Etiam sit amet sapien eget mauris viverra aliquam sit amet
          sollicitudin elit. Integer quis blandit elit. Morbi in varius elit,
          sed tempor enim. Donec et nisi id justo interdum bibendum eu vel
          lectus. Aliquam pulvinar pulvinar libero, id luctus risus euismod at.
          Proin eu metus ut ligula aliquet sodales. Interdum et malesuada fames
          ac ante ipsum primis in faucibus. Vivamus sollicitudin metus quis
          sapien convallis, id vestibulum nisl maximus. Duis ullamcorper, nulla
          molestie elementum dictum, eros orci venenatis leo, quis euismod nisl
          turpis vitae leo. Nulla elementum turpis leo, et convallis nunc
          facilisis id. Praesent vestibulum, neque a varius mollis, turpis ante
          dapibus nibh, sit amet maximus augue velit nec nisl.
        </p>
        <p>
          Duis non ornare nunc. Fusce rhoncus malesuada aliquet. Maecenas et
          bibendum nunc. Suspendisse auctor feugiat lectus et pulvinar. In
          molestie risus non nibh maximus consectetur. Fusce non dignissim urna.
          Aenean enim libero, bibendum quis justo ac, elementum convallis eros.
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
          posuere cubilia curae; Aenean eros velit, pellentesque eget efficitur
          eget, maximus eget sem. Proin nec magna mi. Nullam sit amet nulla mi.
          Aenean in viverra sapien.
        </p>
        <p>
          Nam elit nunc, consequat nec eleifend fringilla, ultrices id metus.
          Donec rhoncus quis neque sed tincidunt. In a auctor ipsum, eget
          molestie magna. Pellentesque posuere risus eu augue sodales, sed
          tristique sapien aliquet. In suscipit ullamcorper facilisis. Nullam
          enim leo, vestibulum at urna nec, fermentum sollicitudin lectus. Sed
          pharetra, elit nec dictum eleifend, neque purus aliquet velit, a
          lobortis orci odio vitae enim.
        </p>
        <p>
          Integer nec sagittis massa. Donec non dui dolor. Sed vel elementum mi,
          sed ornare velit. Curabitur volutpat elementum nulla, a imperdiet
          ligula venenatis at. Sed volutpat nunc pretium, finibus metus ac,
          ullamcorper lorem. Proin finibus sollicitudin ligula id varius.
          Pellentesque dui erat, porttitor non mattis at, hendrerit vel eros.
          Quisque in risus ornare, lacinia leo non, maximus dolor. Nulla
          facilisi.
        </p>
        <p>
          Ut molestie faucibus convallis. Sed feugiat odio lacinia orci
          pellentesque, quis consectetur mauris pharetra. Suspendisse at blandit
          leo. Donec ultricies ligula vitae felis rutrum gravida. Aliquam
          sagittis vulputate efficitur. Fusce at dui id odio vehicula pharetra.
          Nunc maximus ut arcu eget ornare. Maecenas vel tristique lectus. Nulla
          vitae tellus neque. Donec imperdiet sed diam et pharetra. Praesent
          pretium eros vitae augue dignissim rhoncus.
        </p>
        <p>
          Etiam a condimentum metus, id rhoncus ex. Aenean vestibulum velit ac
          vehicula volutpat. Maecenas aliquam hendrerit mauris at sagittis.
          Integer sagittis arcu et metus eleifend, id condimentum libero
          vehicula. Sed consectetur, neque sed ornare convallis, odio nulla
          varius dui, in vehicula ante nunc eget nisi. Curabitur et lacus
          libero. Fusce iaculis metus orci.
        </p>
        <p>
          Cras blandit metus id laoreet venenatis. Ut at lacus at neque ornare
          facilisis non convallis erat. Nam malesuada magna vitae justo
          fermentum, sed fringilla ante iaculis. Suspendisse est sapien, porta
          ut massa ac, varius pulvinar tellus. Aliquam dictum vulputate lorem,
          nec suscipit ante elementum a. In risus justo, venenatis ac tempus
          eget, laoreet id diam. Proin pretium odio scelerisque, aliquet nunc
          accumsan, sagittis augue. Nunc sed nibh luctus, venenatis orci ac,
          consectetur mi. Vivamus venenatis pellentesque ante non malesuada. In
          non eros sit amet nulla fringilla porttitor eget ut lectus. Duis quis
          porttitor justo, non dictum velit. Etiam facilisis nisl lorem, porta
          facilisis lorem scelerisque euismod.
        </p>
        <p>
          Nunc tempus ipsum eget diam convallis bibendum. Mauris efficitur in
          tellus sit amet rhoncus. Aliquam mauris massa, fermentum at commodo
          sit amet, pellentesque nec lacus. Donec vel leo hendrerit, tincidunt
          libero quis, iaculis justo. Aenean gravida accumsan elit, eget
          tincidunt dui. Sed eget porta nulla. Sed in volutpat ex. Etiam nec
          scelerisque velit. Quisque finibus eu est ac vestibulum. Curabitur
          lobortis arcu vel erat maximus, in posuere lacus pellentesque. Sed in
          lorem laoreet, cursus elit interdum, rhoncus libero. Curabitur
          consequat interdum sem eu bibendum. Phasellus tempus, justo nec
          tristique facilisis, ipsum ipsum interdum justo, imperdiet tincidunt
          quam dui ac lectus. Quisque quis leo fringilla, tincidunt magna a,
          mattis massa. Donec tincidunt blandit diam at eleifend.
        </p>
      </div>
      <div className="terms-footer">
        <Button
          type="default"
          size="large"
          className="decline-button"
          onClick={() =>
            logout({
              redirectUri: getRedirectUri(),
            })
          }
        >
          Decline and Logout
        </Button>
        <Button
          type="primary"
          size="large"
          className="agree-button"
          onClick={() => handleUpdateTerms()}
        >
          Agree
        </Button>
      </div>
    </div>
  );
};
